<template>
  <div class="all-box">
    <div class="box">
      <div class="input-box-mobile">
        <el-input
          v-model="input1"
          clearable
          @clear="handleClear"
          class="input"
          placeholder="请输入搜索内容"
        >
          <template slot="append">
            <span @click="search">搜索</span>
          </template>
        </el-input>
      </div>
      <div
        class="box-list"
        v-for="item in highlightedDataList"
        @click="toDetail(item)"
        :key="item.id"
      >
        <div class="left">
          <img :src="item.image" :onerror="defaultImg" style="object-fit: cover;"/>
        </div>
        <div class="right">
          <div v-if="item.title" class="title" v-html="item.title"></div>
          <div v-if="item.name" class="title" v-html="item.name"></div>
          <div
            v-if="item.repertoireName"
            class="title"
            v-html="item.repertoireName"
          ></div>
          <div v-if="item.showTime" class="body">{{ `上演日期：${item.showTime}` }}</div>
          <div v-if="item.descript" class="body" v-html="item.descript"></div>
          <div v-if="item.body" v-html="item.body" class="body"></div>
          <div v-if="item.price" v-html="item.price" class="body"></div>
          <div
            v-if="item.publishPlace"
            class="body"
            v-html="item.publishPlace"
          ></div>
          <div v-if="item.time" class="time">{{ item.time }}</div>
          <div v-if="item.entertainerType" class="type">
            {{ item.entertainerType }}
          </div>
        </div>
      </div>
    </div>
    <PageFooter style="margin-top: 0.3rem" />
  </div>
</template>
<script>
import { getAllList } from "@/service/search";
import { getArticleDetail, articleHit } from "@/service/culture";
export default {
  computed: {
    highlightedDataList () {
      const searchString = this.input1; // 替换为您要搜索的字符串
      if (this.dataList.length !== 0) {
        return this.dataList.map((item) => {
          const title = item.title
            ? item.title.replace(
              new RegExp(searchString, "g"),
              `<span style='color:red'>${searchString}</span>`
            )
            : "";
          const descript = item.descript
            ? item.descript.replace(
              new RegExp(searchString, "g"),
              `<span style='color:red'>${searchString}</span>`
            )
            : "";
          const name = item.name
            ? item.name.replace(
              new RegExp(searchString, "g"),
              `<span style='color:red'>${searchString}</span>`
            )
            : "";
          const publishPlace = item.publishPlace
            ? item.publishPlace.replace(
              new RegExp(searchString, "g"),
              `<span style='color:red'>${searchString}</span>`
            )
            : "";
          const repertoireName = item.repertoireName
            ? item.repertoireName.replace(
              new RegExp(searchString, "g"),
              `<span style='color:red'>${searchString}</span>`
            )
            : "";
          const body = item.body
            ? item.body.replace(
              new RegExp(searchString, "g"),
              `<span style='color:red'>${searchString}</span>`
            )
            : "";
          const price = item.price
            ? item.price.replace(
              new RegExp(searchString, "g"),
              `<span style='color:red'>${searchString}</span>`
            )
            : "";
          return {
            ...item,
            title,
            descript,
            name,
            publishPlace,
            repertoireName,
            body,
            price,
          };
        });
      }
    },
  },
  data () {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/noData.jpg") + '"',
      pager: {
        pageNo: 1,
        pageSize: 10,
        totalPage: 0,
        totalCount: 0,
      },
      form: {
        searchType: "all",
        keywords: "",
      },
      input1: "",
      searchList: ["综合", "购票", "剧目", "资讯", "人艺人", "视频"],
      selectedLetter: "综合",
      number: 24,
      dataList: [],
    };
  },
  created () {
    this.input1 = this.$route.query.search;
    this.form.keywords = this.$route.query.search;
    this.getDate();
  },
  methods: {
    toDetail (item) {
      if (item.item == "repertoire") {
        //剧目

        window.open(`#/performance/detail/${item.id}`, "_self");
      } else if (item.item == "ticketing") {
        //购票
        const href = "http://piao.bjry.com";
        window.open(href, "_self");
      } else if (item.item == "article") {
        //资讯
        const { href } = this.$router.resolve({
          path: "/ArtsInformation/detail",
          query: {
            id: item.id,
            page: "5",
          },
        });
        window.open(href, "_self");
      } else if (item.item == "entertainer") {
        //人艺人
        const { href } = this.$router.resolve({
          path: "/PeopleEntertainers/detail",
          query: {
            id: item.id,
            page: "7",
          },
        });
        window.open(href, "_self");
      } else if (item.item == "videos") {
        //视频
        window.open(`#/culture/video/detail/${item.id}`);
      } else if (item.item == "exhibitionDisplay") {
        //陈列展品
        const { href } = this.$router.resolve({
          path: "/theatreMuseum/exhibitDetail",
          query: {
            id: item.id,
          },
        });
        window.open(href, "_self");
      } else if (item.item == "exhibition") {
        //数字展览
        const { href } = this.$router.resolve({
          path: "/theatreMuseum/digitalDetail",
          query: {
            id: item.id,
          },
        });
        window.open(href, "_self");
      } else if (item.item == "pdf") {
        articleHit(item.id);
        getArticleDetail(item.id).then((res) => {
          window.open(
            `${location.origin}${
              location.pathname || "/"
            }static/read.html?url=${encodeURIComponent(item.source)}&pageNum=${
              res.data.attatchmentUrl
            }`
          );
        });
      } else if (item.item == "theatre" && item.level2 == "shoudu1") {
        const { href } = this.$router.resolve({
          path: "/renyiOverView/capitalTheatre",
        });
        window.open(href, "_self");
      } else if (item.item == "theatre" && item.level2 == "renyishiyan") {
        const { href } = this.$router.resolve({
          path: "/renyiOverView/shiyanjuchang",
        });
        window.open(href, "_self");
      } else if (item.item == "theatre" && item.level2 == "beijin") {
        const { href } = this.$router.resolve({
          path: "/renyiOverView/theatreCenter",
        });
        window.open(href, "_self");
      } else if (item.item == "theatre" && item.level2 == "caoyu") {
        const { href } = this.$router.resolve({
          path: "/renyiOverView/caoyu",
        });
        window.open(href, "_self");
      } else if (item.item == "theatre" && item.level2 == "renyi") {
        const { href } = this.$router.resolve({
          path: "/renyiOverView/xiaojuchang"
        });
        window.open(href, "_self");
      } else if (item.item == "theatre" && item.level2 == "JYJC") {
        const { href } = this.$router.resolve({
          path: "/renyiOverView/juYinTheatre",
        });
        window.open(href, "_self");
      } else if (item.item == "repertoireActor") {
        window.open(`#/performance/detail/${item.id}`, "_self");
      }
    },
    handleClear () {
      this.form.keywords = this.input1;
      this.getDate();
    },
    search () {
      this.form.keywords = this.input1;
      this.getDate();
    },
    async getDate () {
      const params = { ...this.form, ...this.pager };
      const res = await getAllList(params);
      this.dataList = res.data.data;
      this.pager = {
        pageNo: res.data.pageNo,
        totalPage: res.data.totalPage,
        pageSize: 10,
        totalCount: res.data.totalCount,
      };
    },
    selectLetter (item) {
      this.selectedLetter = item;
      if (item == "综合") {
        this.form.searchType = "all";
      } else if (item == "购票") {
        this.form.searchType = "ticketing";
      } else if (item == "资讯") {
        this.form.searchType = "article";
      } else if (item == "人艺人") {
        this.form.searchType = "entertainer";
      } else if (item == "视频") {
        this.form.searchType = "videos";
      } else if (item == "剧目") {
        this.form.searchType = "repertoire";
      }
      this.getDate();
    },
    handlePageChange (val) {
      this.pager.pageNo = val;
      this.getDate();
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  margin-top: 20px;

  .box {
    margin-left: 0.6667rem;
    margin-right: 0.6667rem;

    .box-list {
      display: flex;
      margin-top: 0.2667rem;

      .left {
        width: 2.6667rem;
        height: 3.4667rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        margin-left: 0.2rem;
        width: 5rem;
        text-align: justify;
        font-size: 22/75rem;

        .title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* 设置最大展示行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .body {
          margin-top: 0.1333rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* 设置最大展示行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .type {
          width: 1.3867rem;
          height: 0.5067rem;
          font-weight: 400;
          color: #f9680d;
          font-size: 0.2667rem;
          font-weight: 400;
          text-align: center;
          line-height: 0.5067rem;
          margin-top: 0.1333rem;
          border: 1px solid #f9680d;
        }
      }
    }
  }
}
</style>
<style lang="less">
.input-box-mobile {
  width: 100%;
  //margin-top: 0.4rem;

  .input {
    width: 100%;
    height: 80/75rem;
    background: #f8f8fa;
    border-radius: 8/75rem;

    .el-input__inner {
      height: 100%;
      background: #f8f8fa;
      border: none;
      font-size: 16/75rem;
      padding: 0 120/75rem 0 30/75rem;

      &::placeholder {
        color: #909399;
      }
    }

    .el-input-group__append {
      border: none;
      width: 104/75rem;
      height: 64/75rem;
      background: #f9680d;
      border-radius: 0/75rem 6/75rem 6/75rem 0/75rem;
      color: #ffffff;
      text-align: center;
      font-size: 20/75rem;
      font-weight: 600;
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      right: 8/75rem;
      top: 8/75rem;
      border-radius: 4/75rem;
      padding: 0;

      span {
        display: inline-block;
        width: 100%;
        height: 64/75rem;
        line-height: 64/75rem;
      }
    }
  }
}
</style>
